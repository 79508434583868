<template>
  <div>
    <div class="type">
      <router-link
        v-if="device.id"
        tag="span"
        :to="{ name: 'DeviceCard', params: { id: currentCompanyId, deviceId: device.id } }"
        v-add-class-on-hover="'text-decoration-underline'"
        class="text-decoration-none"
      >
        {{ getDeviceTitle(device.type) }} {{ device.id }}
      </router-link>
      <span v-else>{{ getDeviceTitle(device.type) }} {{ device.id }}</span>
      <v-tooltip :disabled="!showAllTooltips" right>
        <template v-slot:activator="{ on, attrs }">
          <span v-on="on" v-bind="attrs">#{{ device.number }}</span>
        </template>
        <div class="text-tooltip">Номер автомату</div>
      </v-tooltip>
    </div>
    <div class="status">
      <div>
        <v-tooltip :disabled="!showAllTooltips" top>
          <template v-slot:activator="{ on, attrs }">
            <span
              v-if="(device.id && device.is_hard_connected) || (!device.id && device.is_hard_connected)"
              v-bind="attrs"
              v-on="on"
            >
              {{ device.rssi }}
              <img :src="require('../../../assets/images/network.svg')" height="10" alt="network" />
            </span>
          </template>
          <div class="text-tooltip">Рівень сигналу з акваматом</div>
        </v-tooltip>
        <span v-if="device.device_spec_state">{{ device.display }}</span>
        <span v-if="(device.id && device.is_hard_connected) || (!device.id && device.is_hard_connected)">
          {{ device.balance }}₴
        </span>
      </div>
      <template v-if="device.id && device.is_hard_connected">
        <v-row no-gutters>
          <v-col cols="4" offset="4">
            <v-tooltip top open-delay="200">
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on">
                  <AmSVG :fill-color="device.is_online ? device.state_color : 'none'" />
                </div>
              </template>
              <div>
                <h4>Інфо про автомат:</h4>
                <p class="mb-0">
                  ID: <span class="font-weight-medium">{{ device.id }}</span>
                </p>
                <p class="mb-0">
                  Тип:
                  <span class="font-weight-medium"> {{ getMachineDescByTitle(device.type) }} ({{ device.type }}) </span>
                </p>
                <p class="mb-0">
                  Виробник:
                  <span class="font-weight-medium">{{ device.manufacturer }}</span>
                </p>
                <p class="mb-0">
                  Модель:
                  <span class="font-weight-medium">{{ device.model }}</span>
                </p>
                <p class="mb-0">
                  Дата виробництва:
                  <span class="font-weight-medium">{{ device.manufacture_date }}</span>
                </p>
              </div>
            </v-tooltip>
          </v-col>
          <v-col cols="4" class="text-left">
            <img v-show="device.is_blocked" :src="require('../../../assets/images/lock.svg')" alt="lock" />
          </v-col>
        </v-row>
        <div class="status-tech">
          <v-tooltip top :disabled="device.state.length < 50">
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on" class="d-block">{{ device.state }}</span>
              <div v-if="!device.is_online">
                <v-tooltip :disabled="!showAllTooltips" right>
                  <template v-slot:activator="{ on, attrs }">
                    <div v-on="on" v-bind="attrs" class="d-flex justify-space-around">
                      <span>{{ device.last_packet_time | getShortDate }}</span>
                      <span>{{ device.last_packet_time | getShortTime }}</span>
                    </div>
                  </template>
                  <div class="text-tooltip">Час та дата останнього оновлення стану автомату</div>
                </v-tooltip>
              </div>
            </template>
            <span>{{ device.state }}</span>
          </v-tooltip>
        </div>
        <div>
          <span v-if="device.device_spec_state">{{ device.device_spec_state.wash_mode }}</span>
        </div>
      </template>
      <template v-else-if="device.id && !device.is_hard_connected">
        <!--        <div style="visibility: hidden">hidden content</div>-->
        <img :src="require('../../../assets/images/aqua-machine-2.svg')" alt="machine" />
        <div class="mt-1 text-caption">Підключений на сервері</div>
        <div class="mt-3">Статуси не надходили</div>
      </template>
      <template v-else>
        <div class="text-caption" style="line-height: 1.3">Не підключений на сервері</div>
        <div class="status-tech">
          <v-tooltip top :disabled="device.state.length < 50">
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on" class="d-block">{{ device.state }}</span>
            </template>
            <span>{{ device.state }}</span>
          </v-tooltip>
        </div>
        <div v-if="device.device_spec_state">{{ device.device_spec_state.wash_mode }}</div>
      </template>
    </div>
    <div v-if="(device.id && device.is_hard_connected) || (!device.id && device.is_hard_connected)" class="mode">
      <div v-if="device.device_spec_state">
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <div class="d-flex align-center" v-bind="attrs" v-on="on">
              <img :src="require('../../../assets/images/tds.svg')" height="13" alt="icon" />
              <span :style="{ color: tds2Color }" class="ml-1">
                {{ device.device_spec_state.sensors.tds2 }}
              </span>
            </div>
          </template>
          <div class="text-tooltip">
            <span v-if="device.device_spec_state.sensors.tds2 === 0">Мінімальне значення, дистильована вода</span>
            <span v-else-if="device.device_spec_state.sensors.tds2 < 50"
              >Кількість солей води на виході системи в межах норми</span
            >
            <span v-else>Кількість солей води на виході системи перевищує норму</span>
          </div>
        </v-tooltip>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import convertDate from '@/mixins/convertDate'
import convertDevicesTypes from '@/mixins/convertDevicesTypes'

export default {
  name: 'Aquamachine',

  components: {
    AmSVG: () => import('@/components/icons/AmSVG'),
  },

  mixins: [convertDevicesTypes, convertDate],

  props: {
    showAllTooltips: {
      type: Boolean,
      required: true,
    },
    currentCompanyId: {
      type: Number,
      default: null,
      required: true,
    },
    terminal: {
      type: Object,
      default: null,
      required: true,
    },
    device: {
      type: Object,
      default: null,
      required: true,
    },
  },

  data: () => ({}),

  computed: {
    ...mapState('dictionaries', ['machinesTypesDictionary']),

    tds2Color() {
      return this.device.is_online ? (this.device.deviceSpecState?.sensors.tds2 > 50 ? 'red' : 'green') : 'inherit'
    },
  },
}
</script>

<style scoped lang="scss">
@import 'src/assets/scss/table';

.devices {
  &-row {
    display: flex;
    border-bottom: 3px double $table-border-color;
    height: 160px;
    &:last-child {
      //border-bottom: 8px double $table-border-color;
      //border: none;
    }
    &-icons {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 40px;
      border-right: 1px solid $table-border-color;
      img {
        display: block;
        width: 20px;
        height: auto;
        margin: 5px 0;
      }
    }
    &-item {
      //flex: 1;
      //max-width: 120px;
      width: 160px;
      border-right: 1px solid $table-border-color;
      padding: 5px;
      &:last-child:not(:first-child) {
        //border: none;
      }
    }
    .type {
      display: flex;
      justify-content: space-between;
      span {
        &:first-child {
          font-size: 10px;
          font-weight: 200;
          cursor: pointer;
        }
        &:last-child {
          font-size: 12px;
          font-weight: 500;
        }
      }
    }
    .status {
      &-tech {
        font-size: 12px;
        //font-weight: 500;
        height: 36px;
        margin-top: 5px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
      }
      img {
        //display: block;
        //margin: 0 auto;
      }
      & > div {
        &:first-child {
          display: flex;
          flex: 1;
          justify-content: space-between;
          font-size: 9px;
          font-weight: 400;
          min-height: 14px;
          span {
            flex-basis: 0;
            flex-grow: 1;
          }
        }
        &:last-child {
          font-size: 10px;
          font-weight: 100;
          min-height: 15px;
        }
      }
    }
    .mode {
      display: flex;
      justify-content: space-between;
      span {
        font-size: 10px;
        font-weight: 200;
      }
    }
    .balance {
      display: flex;
      justify-content: space-between;
      align-items: center;
      span {
        &:first-child {
          font-size: 10px;
          font-weight: 200;
        }
        &:last-child {
          font-size: 14px;
          font-weight: 400;
        }
      }
    }
  }
  &:last-child {
    .devices-row {
      &:last-child {
        border: none;
      }
    }
  }
}

.text-tooltip {
  max-width: 164px;
}
</style>
